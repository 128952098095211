import React, { useState, useEffect } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { io } from "socket.io-client"; // Import Socket.io client

const appId = '96870960a4dd4a9896317285067638cd';
const channel = 'emergency';

function CallComponent() {
  const [remoteAudioTrack, setRemoteAudioTrack] = useState(null);
  const [localAudioTrack, setLocalAudioTrack] = useState(null);
  const [showPopup, setShowPopup] = useState(true);
  const [client, setClient] = useState(null);
  const [inCall, setInCall] = useState(false);
  const [token, setToken] = useState('');
  const [socket, setSocket] = useState(null); 

  useEffect(() => {
    const agoraClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    setClient(agoraClient);

    agoraClient.on('user-published', async (user, mediaType) => {
      if (mediaType === 'audio') {
        await agoraClient.subscribe(user, mediaType);
        const audioTrack = user.audioTrack;
        setRemoteAudioTrack(audioTrack);
        audioTrack.play();
      }
    });

    agoraClient.on('user-unpublished', () => {
      setRemoteAudioTrack(null);
    });

    return () => {
      agoraClient.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    const socket = io('http://104.219.248.145:4000');

    setSocket(socket);

    socket.on('connect', () => {
      console.log('Socket.io Client Connected');
    });

    socket.on('tokenGenerated', (data) => {
      setToken(data.token);
    });

    socket.on('connect_error', (error) => {
      console.error('Socket.io Connection Error:', error);
    });

    return () => {
      socket.disconnect(); 
    };
  }, []);


  const joinChannel = async () => {
    if (client && token) {
      try {
        await client.join(appId, channel, token);
        const localTrack = await AgoraRTC.createMicrophoneAudioTrack();
        await client.publish([localTrack]);
        setLocalAudioTrack(localTrack);
        setInCall(true);
        console.log('Joined channel successfully');
      } catch (error) {
        console.error('Failed to join the channel:', error);
      }
    } else {
      console.error('Token is not available');
    }
  };

  const leaveChannel = async () => {
    if (client) {
      try {
        await client.leave();
        if (localAudioTrack) {
          localAudioTrack.stop();
          localAudioTrack.close();
          setLocalAudioTrack(null);
        }
        if (remoteAudioTrack) {
          remoteAudioTrack.stop();
          setRemoteAudioTrack(null);
        }
        setInCall(false);
        console.log('Left the channel');
      } catch (error) {
        console.error('Failed to leave the channel:', error);
      }
    }
  };

  return (
    <div>
      {showPopup && (
        <div className="popup">
          <h2>{inCall ? 'In Call' : 'Join the Call'}</h2>
          {inCall ? (
            <div>
              <div>
                <label>Remote Audio: </label>
                {remoteAudioTrack ? <span>Connected</span> : <span>Not Connected</span>}
              </div>
              <button onClick={leaveChannel}>Leave Call</button>
            </div>
          ) : (
            <div>
              <button onClick={joinChannel} disabled={!token}>
                {token ? 'Join' : 'Awaiting Token...'}
              </button>
              <button onClick={() => setShowPopup(false)}>Cancel</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CallComponent;
