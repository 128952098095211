import logo from "./logo.svg";
import "./App.css";
import { Login } from "./components/Login";
import { Routes, Route } from "react-router-dom";
import { Dashboard } from "./components/Dashboard";
import { Users } from "./components/Users";
import  {Edit_emergency}  from "./components/Edit_emergency";
import { Creat_emergency } from "./components/Creat_emergency";
import { Create_user } from "./components/Create_user";
import { Edit_user } from "./components/Edit_user";
import { useAuth } from "./contexts/useAuth";
import { Emergencies } from "./components/Emergencies"

function App() {
  const { user } = useAuth();

  return (
    <div className="wrapper">
      <Routes>
        {!user && <Route path="*" element={<Login />} />}
        {user && (
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="emergencies" element={<Emergencies />} />
            <Route path="users" element={<Users />} />
            <Route path="emergencies/update" element={<Edit_emergency />} />
            {/* <Route path="create" element={<Creat_emergency />} /> */}
            {/* <Route path="emergencies/create" element={<Creat_emergency />} /> */}
            <Route path="users/create" element={<Create_user />} />
            <Route path="users/update/:userId" element={<Edit_user />} />
           {/* <Route path="users/update/:userId" element={<Edit_user />} /> */}

          </Route>
        )}
        {user && <Route path="*" element={<Dashboard />} />}
      </Routes>
    </div>
  );
}

export default App;
