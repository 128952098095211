import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import { Header } from "./Header";
import { Outlet, useLocation } from "react-router-dom";
import {Emergencies} from "../components/Emergencies"
import Calls from "../components/Calls"

export const Dashboard = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      window.location.href = "/dashboard";
    }
  }, [location]);

  return (
    <div className="dashboard-container">
      <Sidebar />
      <Calls/>
      <div className="main-content">
        {location.pathname === "/dashboard/" && <Emergencies />}
        <Outlet />
      </div>
    </div>
  );
};
