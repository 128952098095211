import React, {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useContext,
} from "react";
import { api } from "../lib/axios";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [emergency, setEmergencies] = useState([]);
  const [fetched, setFetched] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const checkUser = async () => {
      try {
        const { data } = await api.get(`/admin/${userInfo?._id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (typeof data.data.user === "object") {
          console.log(data.data.user);
          localStorage.setItem("user", JSON.stringify(data.data.user));
          setUser(data.data.user);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setFetched(true);
      }
    };

    checkUser();
    getAllEmergencies();
  }, []);

  const getAllEmergencies = async () => {
    try {
      const { data } = await api.get(`/emergency`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setEmergencies(data.emergencies);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const login = async (email, password) => {
    try {
      const { data } = await api.post("/users/login", {
        email,
        password,
      });
      console.log(data);
      localStorage.setItem("user", JSON.stringify(data.user));
      setUser(data.user);
      localStorage.setItem("token", data.token);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteEmergency = async (emergencyId) => {
    console.log(emergencyId)
    try {
      const { data } = await api.delete(`/emergency/${emergencyId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setEmergencies(emergency.filter((item) => item._id != emergencyId));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        emergency,
        deleteEmergency,
        setUser,
      }}
    >
      {fetched ? children : null}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export const authenticated = () => useAuth().user != null;
