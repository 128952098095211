import React, { useState } from 'react';
import { api } from '../lib/axios';

export const Create_user = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nationalId, setNationalId] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [additionalPhones, setAdditionalPhones] = useState([]);
  const [sendNotification, setSendNotification] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setSuccess(null);
    setError(null);

    if (password !== passwordConfirm) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    const userData = {
      email,
      password,
      phoneNumber,
      name,
      passwordConfirm,
      additionalPhones,
      sendNotification,
      nationalId
    };

    try {
      const response = await api.post('/users/signup', userData);
      setSuccess('User created successfully!');
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Opprett Bruke</h1> {/* Create user */}
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="/dashboard/users">Hjem</a></li>
                <li className="breadcrumb-item active">Opprett Bruke</li> {/* Create user */}
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="name">Navn</label> {/* Name */}
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">E-post</label> {/* Email */}
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="phoneNumber">Telefon</label> {/* Phone */}
                      <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="nationalId">Nasjonal ID</label> {/* National ID */}
                      <input
                        type="text"
                        className="form-control"
                        id="nationalId"
                        placeholder="Enter national ID"
                        value={nationalId}
                        onChange={(e) => setNationalId(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="password">Passord</label> {/* Password */}
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="passwordConfirm">Bekreft Passord</label> {/* Confirm Password */}
                      <input
                        type="password"
                        className="form-control"
                        id="passwordConfirm"
                        placeholder="Confirm password"
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="additionalPhones">Ekstra Telefonnummer</label> {/* Additional Phones */}
                      <input
                        type="text"
                        className="form-control"
                        id="additionalPhones"
                        placeholder="Enter additional phone numbers separated by commas"
                        onChange={(e) => setAdditionalPhones(e.target.value.split(',').map(phone => phone.trim()))}
                      />
                    </div>

                    <div className="form-group">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="sendNotification"
                          checked={sendNotification}
                          onChange={(e) => setSendNotification(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="sendNotification">Send Notification</label>
                      </div>
                    </div>

                    {loading && <p>Loading...</p>}
                    {success && <p className="text-success">{success}</p>}
                    {error && <p className="text-danger">{error}</p>}
                  </div>

                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                      {loading ? "Sending..." : "Send inn"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
