import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/useAuth";

const NoEmergencies = () => (
  <div className="content-wrapper">
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>Liste over nødsituasjoner</h1>
          </div>
          {/* <div className="col-sm-6 text-right">
            <Link to="create" className="btn btn-success">
              Opprett
            </Link>
          </div> */}
        </div>
      </div>
    </section>
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Ingen nødsituasjoner funnet</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export const Emergencies = () => {
  const { emergency = [], deleteEmergency } = useAuth();  
  if (!emergency || emergency.length === 0) {
    return <NoEmergencies />;
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2 mt-5">
            <div className="col-sm-6">
              <h1>Liste over nødsituasjoner</h1>
            </div>
            {/* <div className="col-sm-6 text-right">
              <Link to="create" className="btn btn-success">
                Opprett
              </Link>
            </div> */}
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Liste over nødsituasjoner</h3>
                </div>
                <div className="card-body p-0 table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: '10%' }}>#</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                        <th>Nødbesjed</th>
                        <th>Videonødmelding</th>
                        <th>Bilde-nødmelding</th>
                        {/* <th>Nødopptak</th> */}
                        {/* <th>Handlinger</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {emergency.map((emergencyItem, index) => (
                        <tr key={emergencyItem._id}>
                          <td>{index + 1}</td>
                          <td>{emergencyItem.lat}</td>
                          <td>{emergencyItem.long}</td>
                          <td>{emergencyItem.emergencyText}</td>
                          <td>
                            {emergencyItem.emergencyVideo && (
                              <video width="100" src={emergencyItem.emergencyVideo} controls />
                            )}
                          </td>
                          <td>
                            {emergencyItem.emergencyPhoto && (
                              <img width="85" src={emergencyItem.emergencyPhoto} alt="Emergency" />
                            )}
                          </td>
                          <td>{emergencyItem.emergencyRecord}</td>
                          {/* <td className="d-flex">
                            <Link to="update" className="btn btn-success me-2">
                              Oppdatering
                            </Link>
                            <button
                              className="btn btn-danger"
                              onClick={() => deleteEmergency(emergencyItem._id)}
                            >
                              Slett
                            </button>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
