import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { api } from "../lib/axios";

export const Users = () => {
  const [users, setUsers] = useState();
  
  useEffect(() => {
    const getAllUsers = async () => {
      try {
        const { data } = await api.get("/admin", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setUsers(data.data.users);
      } catch (error) {
        console.log(error);
      }
    };
    getAllUsers();
  }, []);
  
  const deleteUsers = async (userId) => {
    try {
      const { data } = await api.delete(`/admin/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setUsers(users.filter((user) => user._id !== userId));
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2 align-items-center mt-5">
            <div className="col-sm-6">
              <h1>Users List</h1>
            </div>
            <div className="col-sm-6  text-start">
              <Link to="create" className="btn btn-success">
                Opprett
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Brukerliste</h3>
                </div>
                <div className="card-body p-0 table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: 10 }}>#</th>
                        <th>Navn</th>
                        <th>E-post</th>
                        <th>Telefon</th>
                        <th>Tilleggstelefoner</th>
                        <th>Nasjonal ID</th>
                        <th>Handlinger</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users?.map((user, index) => (
                        <tr key={user._id}>
                          <td>{index + 1}</td>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.phoneNumber}</td>
                          <td>{user.additionalPhones}</td>
                          <td>{user.nationalId}</td>
                          <td className="d-flex">
                            <Link
                              to={`update/${user._id}`}
                              className="btn btn-success me-2"
                            >
                              Oppdatering
                            </Link>
                            <button
                              className="btn btn-danger"
                              onClick={() => deleteUsers(user._id)}
                            >
                              Slett
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
