import React, { useRef } from "react";
import { useAuth } from "../contexts/useAuth";
import { api } from "../lib/axios";
import { useParams } from "react-router-dom";

export const Edit_user = () => {
  const { user, setUser } = useAuth();
  const { userId } = useParams();
  const formRef = useRef(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    try {
      const { data } = await api.patch(
        "/users/updateProfile",
        {
          name: formData.get("name"),
          email: formData.get("email"),
          phoneNumber: formData.get("phoneNumber"),
          nationalId: formData.get("nationalId"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setUser(data.data.user);
      localStorage.setItem("user", JSON.stringify(data.data.user));

      formRef.current.reset(); // Reset the form after successful API call
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Oppdater Bruker</h1> {/* Update User */}
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/dashboard/users">Hjem</a>
                </li>
                <li className="breadcrumb-item active">Oppdater Bruker</li>{" "}
                {/* Update User */}
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <form onSubmit={handleSubmit} ref={formRef}>
                  {" "}
                  {/* Attach the ref to the form */}
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="name">Navn</label> {/* Name */}
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Enter name"
                        defaultValue={user.name}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">E-post</label> {/* Email */}
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter email"
                        defaultValue={user.email}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phoneNumber">Telefon</label> {/* Phone */}
                      <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        placeholder="Enter phone number"
                        name="phoneNumber"
                        defaultValue={user.phoneNumber}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="nationalId">Nasjonal ID</label>{" "}
                      {/* National ID */}
                      <input
                        type="text"
                        className="form-control"
                        id="nationalId"
                        placeholder="Enter national ID"
                        name="nationalId"
                        defaultValue={user.nationalId}
                      />
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Send inn
                    </button>{" "}
                    {/* Submit */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
