import Button from 'react-bootstrap/Button';
import { NavLink } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Sidebar() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary p-3">
      <Container fluid>
        <Navbar.Brand href="/" className="text-center">
          Nød
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarScroll" />
        {/* <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form> */}

        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link as={NavLink} to="/dashboard/emergencies" >
              <i className="fas fa-tachometer-alt"></i> Nødsituasjoner
            </Nav.Link>
            <Nav.Link as={NavLink} to="/dashboard/users" >
              <i className="fas fa-users"></i> Brukere
            </Nav.Link>
            <Nav.Link as={NavLink} to="/" onClick={() => localStorage.clear()}>
              <i className="fas fa-sign-out-alt"></i> Logg ut
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Sidebar;